import logo from "./logo.webp"

const  clientData = {
    client_entity: 15,
    attorney_firm: 'dayeslawfirm.com',
    attorney_name: 'Dennis Evans',
    attorney_number: '1(800) 503-2000',
    attorney_email: 'dennise@dayeslawfirm.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_dayes_law+(720p).mp4',
    mainColor: '#0a4d87',
    secondaryColor: '#179647',
    siteLink: 'http://dayeslawfirm.com',
    logo
}

export default clientData